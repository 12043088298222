<template>
	
	<navbar aria-label="Bottom navbar" color="white" class="navbar-bottom" :toggler="false">
		
		<mdb-navbar-nav nav class="w-100">
			
			<template v-for="item in nav">
				
				<router-link v-if="shouldShow(item.key) && item.type === 'btn'" :to="modifiedTo(item)" tag="li" class="nav-item" :key="item.key">
					<btn
						outline="primary"
						rounded
						class="text-uppercase font-weight-bold"
					>{{ $t('titles.' + item.key) }}</btn>
				</router-link>
				
				<mdb-nav-item
					v-else-if="shouldShow(item.key)"
					:key="item.key"
					:to="item.to"
					:aria-label="item.ariaLabel"
					waves-fixed
					exact
				>
					<icon :name="item.icon.name" :type="item.icon.type" :aria-label="$t('titles.' + item.key)" class="font-lg mb-0"></icon>
				</mdb-nav-item>
			
			</template>
			
		</mdb-navbar-nav>
	
	</navbar>

</template>

<script>
	
	import { mapState } from 'vuex';
	import NavBottom from '../navBottom';
	
	export default {
		
		computed: {
			
			...mapState({
				title: state => state.app.title
			}),
			
			nav() {
				
				return NavBottom;
				
			}
			
		},

		mounted() {

			let item = document.getElementsByClassName('uei-icon-home')[0].parentElement;

			item.setAttribute('aria-label', 'Dashboard');

		},
		
		methods: {
			
			shouldShow(key) {
				
				if (key === 'Share') {
					
					return (
						(/^Application/.test(this.$route.name) && this.$route.name !== 'ApplicationList')
						|| /^CoBorrower/.test(this.$route.name)
					);
					
				}
				
				return true;
			
			},
			
			modifiedTo(item) {
				
				let to = item.to;
				
				if (item.key === 'Share') {
					
					to = Object.assign({}, item.to, {params: this.$route.params});
					
				}
				
				return to;
				
			}
			
		}
		
	};

</script>

<style lang="scss" scoped>
	@media screen and (max-width: 350px) {
		
		.nav-item {
			margin: 0.5rem 0;
		}
		
		.btn {
			margin: 0.5rem 0 2rem;
		}
		
	}
</style>
