import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';
import store from '../store';
import { i18n, checkRouteForLocaleSwitch } from '../i18n';

Vue.use(Router);

const appRouter = new Router({
	//mode: 'history',
	base: process.env.BASE_URL,
	routes: routes,
	scrollBehavior(to, from, savedPosition) {

		if (savedPosition) {

			return savedPosition;

		} else {

			return { x: 0, y: 0 };

		}

	}
});

appRouter.beforeEach(async (to, from, next) => {

	store.dispatch('saveLoading', true);
	store.dispatch('throwFatalError', false);

	// check for lang in url query

	await checkRouteForLocaleSwitch(to);

	// check token if needed, will log out user if needed

	if (to.matched.some(record => record.meta.requiresAuth)) {

		await store.dispatch('checkAuthUserToken');

		if (!store.state.auth.authUserToken) {

			return next({
				name: 'Login',
				query: (to.fullPath !== '/') ? {redirect: to.fullPath} : {}
			});

		} else if (to.name === 'Login' && store.state.auth.authUserToken) {

			return next({
				name: 'Dashboard'
			});

		}

		// pull options for application form, done early because long request
		store.dispatch('getApplicationsOptions');

	}

	// set page title

	store.dispatch('saveTitle', i18n.t('titles.' + to.name));

	next();

});

appRouter.beforeResolve(async (to, from, next) => {

	next();

});

appRouter.afterEach(async (to, from) => {

	store.dispatch('saveLoading', false);

});

export default appRouter;
