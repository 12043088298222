<template>

	<div class="default-layout" :id="pageId" ref="layoutWrapper">

		<header>
			<nav-top></nav-top>
		</header>

		<main>

			<transition name="fade">
				<router-view :key="$route.fullPath"></router-view>
			</transition>

		</main>

		<footer ref="navBottom">
			<nav-bottom></nav-bottom>
		</footer>

	</div>

</template>

<script>

	import { mapActions, mapState } from 'vuex';
	import { kebabCase } from 'change-case';
	import NavTop from './navTop';
	import NavBottom from './navBottom';
	let eventListener, resizeTimeout;

	export default {

		components: {
			NavTop,
			NavBottom
		},

		data() {

			return {
				resizeTimeout
			};

		},

		computed: {

			...mapState({
				locale: state => state.app.locale
			}),

			pageId() {

				return kebabCase(this.$route.name);

			}

		},

		mounted() {

			this.resizeFooter();

			window.addEventListener('resize', this.windowResize());

		},

		beforeDestroy() {

			clearTimeout(this.resizeTimer);

			window.removeEventListener('resize', this.windowResize());

		},

		methods: {

			...mapActions([
				'saveLocale'
			]),

			changeLocale(locale) {

				this.saveLocale(locale);

			},

			windowResize() {

				clearTimeout(this.resizeTimer);

				let _self = this;

				this.resizeTimer = setTimeout(function () {

					// Run code here, resizing has "stopped"
					_self.resizeFooter();

				}, 250);

			},

			resizeFooter() {

				if (this.$refs.navBottom) {

					let footer = this.$refs.navBottom.clientHeight;

					this.$refs.layoutWrapper.style.paddingBottom = (footer + 20) + 'px';

				}

			}

		}

	};

</script>
