<template>

	<navbar
		dark
		color="gray"
		expand="xl"
		animated
		animation="2"
		shadow
		class="navbar-top"
		aria-label="Top navbar"
	>

		<h1 class="page-title">{{ title }}</h1>

		<!--<form name="top-search-form" method="GET" @submit.prevent="onSearch">

			<form-field
				v-model="q"
				type="search"
				name="search"
				:placeholder="$t('forms.search')"
				wrapper-class="search-wrapper"
				bootstrap
				waves
				waves-fixed
			></form-field>

		</form>-->

		<mdb-navbar-toggler ref="toggler">
			<mdb-navbar-nav right>

				<template v-for="item in nav">

					<mdb-dropdown v-if="item.children && item.children.length" :key="item.key" tag="li" class="nav-item" right>

						<mdb-dropdown-toggle tag="a" nav-link transparent slot="toggle" waves-fixed>
							{{ $t('titles.' + item.key) }}
						</mdb-dropdown-toggle>

						<mdb-dropdown-menu tag="ul" right>

							<li v-for="child in item.children" :key="item.key + '-' + child.key">
								<router-link :to="child.to" class="dropdown-item" exact>
									{{ $t('titles.' + child.key) }}
								</router-link>
							</li>

						</mdb-dropdown-menu>

					</mdb-dropdown>

					<mdb-nav-item v-else-if="item.to" :key="item.key" :to="item.to" waves-fixed exact>
						{{ $t('titles.' + item.key) }}
					</mdb-nav-item>


				</template>

				<li class="nav-item" waves-fixed>

					<router-link :to="{query: {l: $t('localeSwitch.letters').toLowerCase()}}" class="nav-link navbar-link" waves-fixed exact>
						{{ $t('localeSwitch.letters') }}
					</router-link>

				</li>

			</mdb-navbar-nav>
		</mdb-navbar-toggler>

	</navbar>

</template>

<script>

	import { kebabCase } from 'change-case';
	import { mapState } from 'vuex';
	import NavTop from '../navTop';

	export default {

		computed: {

			...mapState({
				title: state => state.app.title,
				applicationLists: state => state.application.applicationLists
			}),

			nav() {

				// if (!this.applicationLists || !Object.keys(this.applicationLists).length) {

				return NavTop;

				// }

				/*let listNavItem = NavTop.find(item => item.key === 'Contracts');

				if (!listNavItem || !listNavItem.children) {

					return NavTop;

				}

				Object.keys(this.applicationLists).forEach(key => {

					// console.log('this.applicationLists[ ' + key + ' ]', this.applicationLists[key]);
					// this.applicationLists[key]

					if (listNavItem.children.find(item => item.key === key)) {

						return;

					}

					let list = this.applicationLists[key];

					listNavItem.children.push({
						key: key,
						label: list.label,
						to: {
							name: 'ApplicationList',
							params: {
								status: kebabCase(list.key)
							}
						},
						order: list.order
					});

				});

				listNavItem.children.sort((a, b) => a.order - b.order);

				return NavTop;*/

			}

		}

	};

</script>

<style lang="scss">

	.navbar-nav .nav-item.dropdown .dropdown-menu {
		max-height: 230px;
		overflow-y: scroll;
	}

	.navbar .page-title {
		@include media-breakpoint-down('sm') {
			width: calc(100% - 54px) !important;
			padding-right: 54px;
		}
	}

</style>
