import 'unorm';
import 'custom-event-polyfill';
import Vue from 'vue';
import VueTheMask from 'vue-the-mask';
import VueScrollTo from 'vue-scrollto';
import VueMatchHeights from 'vue-match-heights';
import Loading from 'vue-loading-overlay';
import { default as Snotify, SnotifyPosition } from 'vue-snotify';
import './assets/scss/app.scss';
import GlobalComponents from './app/elements/globalComponents';
import Btn from './app/elements/components/Btn';
import { App, router, store, i18n } from './app';

// missing forEach on NodeList for IE11
if (window.NodeList && !NodeList.prototype.forEach) {

	NodeList.prototype.forEach = Array.prototype.forEach;

}

Vue.directive("trim", {
	inserted: function (el) {

		el.style.whiteSpace = 'pre-line';

		let str = el.innerHTML;
		el.innerHTML = str.trim();

	}
});

Vue.use(Snotify, {
	global: {
		maxOnScreen: 3,
		maxAtPosition: 3,
		// filterDuplicates: false
	},
	toast: {
		position: SnotifyPosition.centerTop,
		timeout: 1000 * 10,
		bodyMaxLength: 500,
		titleMaxLength: 50,
		closeOnClick: true,
		maxOnScreen: 3,
		maxAtPosition: 3,
		// oneAtTime: true
	}
});

Vue.use(VueTheMask);

Vue.use(GlobalComponents);

Vue.use(Loading);

Vue.use(VueScrollTo, {
	offset: -25,
	easing: 'ease-in-out'
});

Vue.use(VueMatchHeights, {
	disabled: [768], // Optional: default viewports widths to disabled resizing on. Can be overridden per usage
});

Vue.component('loading', Loading);
Vue.component('Btn', Btn);

Vue.config.productionTip = false;

new Vue({
	render: h => h(App),
	router,
	store,
	i18n
}).$mount('#app');
