export default {

	SAVE_LOADING: (state, payload) => {

		state.loading = payload;

	},

	SHOW_CONFIRM: (state, payload) => {

		state.alerts.confirm.push(payload);

	},

	SHOW_SUCCESS_ALERT: (state, payload) => {

		state.alerts.success.push(payload);

	},

	SHOW_ERROR_ALERT: (state, payload) => {

		state.alerts.error.push(payload);

	},

	SHOW_INFO_ALERT: (state, payload) => {

		state.alerts.info.push(payload);

	},

	REMOVE_ALERT: (state, payload) => {

		if (!payload) {

			return;

		}

		let removed;

		Object.keys(state.alerts).forEach(type => {

			let id = (typeof payload === 'string') ? payload : payload.id;
			let pos = state.alerts[type].indexOf(id);

			if (pos >= 0) {

				state.alerts[type].splice(pos, 1);
				removed = true;

			}

		});

		return (removed);

	},

	REMOVE_ALERTS: (state) => {

		state.alerts.success.splice(0, state.alerts.success.length);
		state.alerts.error.splice(0, state.alerts.success.length);
		state.alerts.info.splice(0, state.alerts.success.length);

	},

	SAVE_FATAL_ERROR: (state, payload) => {

		state.fatalError = payload;

	},

	SAVE_LOCALE: (state, payload) => {

		state.locale = payload;

	},

	SAVE_TITLE: (state, payload) => {

		state.title = payload;

		document.title = `UEI Financial • ${payload}`;

	}

};
