// don't forget to make sure there is a translation with the same key in the component

export default [
	
	{
		key: 'Dashboard',
		ariaLabel: 'Dashboard',
		to: {name: 'Dashboard'},
		icon: {
			type: 'uei-icon',
			name: 'home'
		}
	}
	

];